<template>
  <app-select
    filter
    :label="$t('date')"
    content-class="time-select__menu"
    label-icon="icon-calendar"
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #prepend-item>
      <ValidationObserver ref="form" tag="form">
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6">
              {{ $t('of') }}
              <app-date-picker
                v-model="newSelection.start"
                name="of"
                placeholder="dateHint"
                append-icon
              />
            </v-col>
            <v-col cols="12" sm="6">
              {{ $t('to') }}
              <app-date-picker
                v-model="newSelection.end"
                :min="minEndDate"
                :rules="newSelection.start ? `min_date:${minEndDate}` : ''"
                name="to"
                placeholder="dateHint"
                append-icon
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col v-for="(option, index) in options" :key="index" cols="auto">
              <app-link-btn
                :title="option.label"
                variant="muted"
                @click="setQuickSelect(option.value)"
              />
            </v-col>
          </v-row>
          <v-row dense class="mt-4">
            <v-col>
              <app-btn min-height="30" class="subtitle-1" block @click="filterItems">
                {{ $t('filter') }}
              </app-btn>
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </template>
  </app-select>
</template>

<script>
import { formatDateToTimestamp, formatTimestampToDate } from '@/helper/filter/formatDate';
import {
  MILLISECONDS_PER_MONTH,
  MILLISECONDS_PER_QUARTER,
  MILLISECONDS_PER_WEEK,
  MILLISECONDS_PER_YEAR
} from '@/statics/timePeriods';

export default {
  name: 'DateRangeSelect',

  props: {
    value: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    newSelection: {
      start: '',
      end: ''
    },
    showMenu: false
  }),

  computed: {
    options() {
      return [
        {
          label: this.$t('timePeriodOptions.today'),
          value: [Date.now(), Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastWeek'),
          value: [Date.now() - MILLISECONDS_PER_WEEK, Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastMonth'),
          value: [Date.now() - MILLISECONDS_PER_MONTH, Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastQuarter'),
          value: [Date.now() - MILLISECONDS_PER_QUARTER, Date.now()]
        },
        {
          label: this.$t('timePeriodOptions.lastYear'),
          value: [Date.now() - MILLISECONDS_PER_YEAR, Date.now()]
        }
      ];
    },
    activeSelection() {
      // [periodStartInMs, periodEndInMs] or null
      return this.value?.[0] ?? null;
    },
    minEndDate() {
      if (!this.newSelection.start) {
        return;
      }

      return this.newSelection.start;
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        // set already selected time period as default
        if (this.activeSelection) {
          this.newSelection.start = this.activeSelection.start
            ? formatTimestampToDate(this.activeSelection.start / 1000)
            : '';
          this.newSelection.end = this.activeSelection.end
            ? formatTimestampToDate(this.activeSelection.end / 1000)
            : '';
          return;
        }

        this.newSelection = {
          start: '',
          end: ''
        };
      }
    }
  },

  methods: {
    async filterItems() {
      // return if start is past end date or current selection is already active
      if (
        !(await this.$refs.form.validate()) ||
        (this.activeSelection &&
          String(this.activeSelection.start) ===
            String(formatDateToTimestamp(this.newSelection.start) * 1000) &&
          String(this.activeSelection.end) ===
            String(formatDateToTimestamp(this.newSelection.end) * 1000))
      ) {
        return;
      }

      if (!this.newSelection.start && !this.newSelection.end) {
        return this.$emit('input', undefined);
      }

      this.$emit('input', [
        {
          start: this.newSelection.start
            ? formatDateToTimestamp(this.newSelection.start) * 1000
            : null,
          end: this.newSelection.end ? formatDateToTimestamp(this.newSelection.end) * 1000 : null
        }
      ]);
    },

    setQuickSelect([startInMs, endInMs]) {
      this.newSelection.start = formatTimestampToDate(startInMs / 1000);
      this.newSelection.end = formatTimestampToDate(endInMs / 1000);
      this.filterItems();
    }
  }
};
</script>

<style>
.time-select__menu {
  width: 400px;
}

.time-select__menu .v-list-item {
  display: none;
}
</style>
